import { default as editEkwTqchJEjMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexWWvaI0X8txMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93wyOXbHRx8XMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editXFprYv5ipOMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as index5iQdPNm2mnMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93wvjYBc5B9qMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexHZCklPlSybMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newX5fJ7VBkCWMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plans8hwVTiF0esMeta } from "/codebuild/output/src3272106254/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editw8MtskAJlrMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as index9yhKu5U215Meta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93rei9LGhIquMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexvb9xTMsGpVMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newlWt2nGJnjUMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansWPK9uZyVDvMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editcICiqdjVx6Meta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexm85CslQvqGMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_933eg8m0MrhtMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexyh4ywwq2uRMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newr7ly6fn10lMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsYV8dpMdYRcMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as edit9DlWpxjxTbMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexqU5RiHiDT4Meta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editobsqQ3fTdLMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexP8QrwfW1oEMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93a1o6MbB4pAMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexLPwyR7YP6eMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as new9eV5i9o7VpMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsIK2qe9x4nbMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93hsWky97m7tMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexuAaXI8Jn65Meta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selection3hv3lmy2LZMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardFKkfLPfqtHMeta } from "/codebuild/output/src3272106254/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accountsIZLUwA90v8Meta } from "/codebuild/output/src3272106254/src/pages/admin/accounts.vue?macro=true";
import { default as editrFS4640vHqMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexeiOUaVWeEfMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93SqyUGx3IwzMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexQ6VJ6EEmCLMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newKXlUXXyWMXMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersZMTMM6hSXZMeta } from "/codebuild/output/src3272106254/src/pages/admin/admin_users.vue?macro=true";
import { default as indexFe1tC7qjgrMeta } from "/codebuild/output/src3272106254/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsXuM7upl3cBMeta } from "/codebuild/output/src3272106254/src/pages/admin/audit_trails.vue?macro=true";
import { default as editChxaNKrXtQMeta } from "/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexSMRB1UFXipMeta } from "/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93uZ8Rm8mMpIMeta } from "/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexGiJ6TGpgi1Meta } from "/codebuild/output/src3272106254/src/pages/admin/billings/index.vue?macro=true";
import { default as newB9R71adLMJMeta } from "/codebuild/output/src3272106254/src/pages/admin/billings/new.vue?macro=true";
import { default as billingseMgx9Giot1Meta } from "/codebuild/output/src3272106254/src/pages/admin/billings.vue?macro=true";
import { default as editzbbPsjHEjdMeta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexFfq1esjWwEMeta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_939DflBHHz7oMeta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexuWICYQaYDPMeta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as new8hfA44Xkf0Meta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idshiQvROtqWeMeta } from "/codebuild/output/src3272106254/src/pages/admin/caller_ids.vue?macro=true";
import { default as edit0zo0e7nUdSMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexlKGiXnqJrCMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_934wbz9NkCmrMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editDLrHgEN43CMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as indexWmeem4BMN5Meta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as edit0dsGEmcNPdMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as index9eFSMGrEKUMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_93kDOcS90aplMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexK98YhVgwzmMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newAh1wJhqGxOMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionsNc8wWEJdRiMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93yL0ptyLcb4Meta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as index8iEUaxko1uMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newVWh8qLWAXyMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsY69l7Nx2hIMeta } from "/codebuild/output/src3272106254/src/pages/admin/campaigns.vue?macro=true";
import { default as indexhR8arxEfnuMeta } from "/codebuild/output/src3272106254/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93ALIgQb5QfMMeta } from "/codebuild/output/src3272106254/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexavbMHimUQlMeta } from "/codebuild/output/src3272106254/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newTIGcJatkZlMeta } from "/codebuild/output/src3272106254/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsrr9mITWvgEMeta } from "/codebuild/output/src3272106254/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexoGrZNoCEQ2Meta } from "/codebuild/output/src3272106254/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardc59NiCtcS8Meta } from "/codebuild/output/src3272106254/src/pages/admin/dashboard.vue?macro=true";
import { default as indexC1hUugGBsFMeta } from "/codebuild/output/src3272106254/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_939nfTX4XL3fMeta } from "/codebuild/output/src3272106254/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexhqhQhdfA58Meta } from "/codebuild/output/src3272106254/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newBUpky6NQ5nMeta } from "/codebuild/output/src3272106254/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesL5OL76DjoLMeta } from "/codebuild/output/src3272106254/src/pages/admin/inquiries.vue?macro=true";
import { default as indexzMXWvtAET5Meta } from "/codebuild/output/src3272106254/src/pages/admin/login/index.vue?macro=true";
import { default as loginS6KQz9A9okMeta } from "/codebuild/output/src3272106254/src/pages/admin/login.vue?macro=true";
import { default as newYbStD5tJd7Meta } from "/codebuild/output/src3272106254/src/pages/admin/password/new.vue?macro=true";
import { default as indexsOif6N6oT5Meta } from "/codebuild/output/src3272106254/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsYVKEbONqJdMeta } from "/codebuild/output/src3272106254/src/pages/admin/reports.vue?macro=true";
import { default as editqUNgBEY51xMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indextPIlkfgNQzMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93YKxk2MLW2JMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexY2kue38ZSDMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newkTfYvGQKZtMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingswdOofXzfXEMeta } from "/codebuild/output/src3272106254/src/pages/admin/sms_maskings.vue?macro=true";
import { default as edit9UTZLzGrSEMeta } from "/codebuild/output/src3272106254/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexRp9TSwZ2IHMeta } from "/codebuild/output/src3272106254/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_9306OJLxuFmcMeta } from "/codebuild/output/src3272106254/src/pages/admin/users/[user_id].vue?macro=true";
import { default as index1EGGAC7R9pMeta } from "/codebuild/output/src3272106254/src/pages/admin/users/index.vue?macro=true";
import { default as newvEl9Z2sCD8Meta } from "/codebuild/output/src3272106254/src/pages/admin/users/new.vue?macro=true";
import { default as users4ekXyan4CXMeta } from "/codebuild/output/src3272106254/src/pages/admin/users.vue?macro=true";
import { default as editMLd8dq1VWiMeta } from "/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexQkRnkEEwGCMeta } from "/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_931axhXNScP6Meta } from "/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminddZXzBMheZMeta } from "/codebuild/output/src3272106254/src/pages/admin.vue?macro=true";
import { default as indexyid105n2UAMeta } from "/codebuild/output/src3272106254/src/pages/audit_logs/index.vue?macro=true";
import { default as indexMxCu4EYzLYMeta } from "/codebuild/output/src3272106254/src/pages/billings/index.vue?macro=true";
import { default as indexIarHwlkNTrMeta } from "/codebuild/output/src3272106254/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93iuQsTgMn5AMeta } from "/codebuild/output/src3272106254/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexEiJFww6DNnMeta } from "/codebuild/output/src3272106254/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexbgrZhP0tnnMeta } from "/codebuild/output/src3272106254/src/pages/campaigns/index.vue?macro=true";
import { default as indexzRYCRQicecMeta } from "/codebuild/output/src3272106254/src/pages/dashboard/index.vue?macro=true";
import { default as indexq61bvnzQF1Meta } from "/codebuild/output/src3272106254/src/pages/help/index.vue?macro=true";
import { default as indexgiM0ZGBKqgMeta } from "/codebuild/output/src3272106254/src/pages/index.vue?macro=true";
import { default as indexZmAlPquTNDMeta } from "/codebuild/output/src3272106254/src/pages/inquiry/index.vue?macro=true";
import { default as editor6tZb0FcfLoMeta } from "/codebuild/output/src3272106254/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as index6IMMw3ceTjMeta } from "/codebuild/output/src3272106254/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailVaGlcKa5BdMeta } from "/codebuild/output/src3272106254/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardhYkjUPEsTNMeta } from "/codebuild/output/src3272106254/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as index3kwEan0LG9Meta } from "/codebuild/output/src3272106254/src/pages/profiles/index.vue?macro=true";
import { default as indexKPxeQiw09CMeta } from "/codebuild/output/src3272106254/src/pages/sub_users/users/index.vue?macro=true";
import { default as index2tfIls3lcrMeta } from "/codebuild/output/src3272106254/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexbWVp8SdRt9Meta } from "/codebuild/output/src3272106254/src/pages/users/password/edit/index.vue?macro=true";
import { default as index7oUqFLRCI8Meta } from "/codebuild/output/src3272106254/src/pages/users/password/new/index.vue?macro=true";
import { default as indexpxiu5IVJt2Meta } from "/codebuild/output/src3272106254/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminddZXzBMheZMeta?.name ?? "admin",
    path: adminddZXzBMheZMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93wyOXbHRx8XMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editEkwTqchJEjMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editEkwTqchJEjMeta?.path ?? "edit",
    meta: editEkwTqchJEjMeta || {},
    alias: editEkwTqchJEjMeta?.alias || [],
    redirect: editEkwTqchJEjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWWvaI0X8txMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexWWvaI0X8txMeta?.path ?? "",
    meta: indexWWvaI0X8txMeta || {},
    alias: indexWWvaI0X8txMeta?.alias || [],
    redirect: indexWWvaI0X8txMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93wyOXbHRx8XMeta?.name ?? undefined,
    meta: _91account_plan_id_93wyOXbHRx8XMeta || {},
    alias: _91account_plan_id_93wyOXbHRx8XMeta?.alias || [],
    redirect: _91account_plan_id_93wyOXbHRx8XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_plans8hwVTiF0esMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93wvjYBc5B9qMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editXFprYv5ipOMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editXFprYv5ipOMeta?.path ?? "edit",
    meta: editXFprYv5ipOMeta || {},
    alias: editXFprYv5ipOMeta?.alias || [],
    redirect: editXFprYv5ipOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index5iQdPNm2mnMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: index5iQdPNm2mnMeta?.path ?? "",
    meta: index5iQdPNm2mnMeta || {},
    alias: index5iQdPNm2mnMeta?.alias || [],
    redirect: index5iQdPNm2mnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93wvjYBc5B9qMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93wvjYBc5B9qMeta || {},
    alias: _91account_requested_plan_id_93wvjYBc5B9qMeta?.alias || [],
    redirect: _91account_requested_plan_id_93wvjYBc5B9qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexHZCklPlSybMeta?.name ?? "admin-account_requested_plans",
    path: indexHZCklPlSybMeta?.path ?? "",
    meta: indexHZCklPlSybMeta || {},
    alias: indexHZCklPlSybMeta?.alias || [],
    redirect: indexHZCklPlSybMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newX5fJ7VBkCWMeta?.name ?? "admin-account_requested_plans-new",
    path: newX5fJ7VBkCWMeta?.path ?? "new",
    meta: newX5fJ7VBkCWMeta || {},
    alias: newX5fJ7VBkCWMeta?.alias || [],
    redirect: newX5fJ7VBkCWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plans8hwVTiF0esMeta?.name ?? undefined,
    meta: account_requested_plans8hwVTiF0esMeta || {},
    alias: account_requested_plans8hwVTiF0esMeta?.alias || [],
    redirect: account_requested_plans8hwVTiF0esMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accountsIZLUwA90v8Meta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93hsWky97m7tMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansWPK9uZyVDvMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93rei9LGhIquMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editw8MtskAJlrMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editw8MtskAJlrMeta?.path ?? "edit",
    meta: editw8MtskAJlrMeta || {},
    alias: editw8MtskAJlrMeta?.alias || [],
    redirect: editw8MtskAJlrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9yhKu5U215Meta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: index9yhKu5U215Meta?.path ?? "",
    meta: index9yhKu5U215Meta || {},
    alias: index9yhKu5U215Meta?.alias || [],
    redirect: index9yhKu5U215Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93rei9LGhIquMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93rei9LGhIquMeta || {},
    alias: _91account_requested_plan_id_93rei9LGhIquMeta?.alias || [],
    redirect: _91account_requested_plan_id_93rei9LGhIquMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexvb9xTMsGpVMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexvb9xTMsGpVMeta?.path ?? "",
    meta: indexvb9xTMsGpVMeta || {},
    alias: indexvb9xTMsGpVMeta?.alias || [],
    redirect: indexvb9xTMsGpVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newlWt2nGJnjUMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newlWt2nGJnjUMeta?.path ?? "new",
    meta: newlWt2nGJnjUMeta || {},
    alias: newlWt2nGJnjUMeta?.alias || [],
    redirect: newlWt2nGJnjUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansWPK9uZyVDvMeta?.name ?? undefined,
    meta: account_requested_plansWPK9uZyVDvMeta || {},
    alias: account_requested_plansWPK9uZyVDvMeta?.alias || [],
    redirect: account_requested_plansWPK9uZyVDvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsYV8dpMdYRcMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_933eg8m0MrhtMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editcICiqdjVx6Meta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editcICiqdjVx6Meta?.path ?? "edit",
    meta: editcICiqdjVx6Meta || {},
    alias: editcICiqdjVx6Meta?.alias || [],
    redirect: editcICiqdjVx6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexm85CslQvqGMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexm85CslQvqGMeta?.path ?? "",
    meta: indexm85CslQvqGMeta || {},
    alias: indexm85CslQvqGMeta?.alias || [],
    redirect: indexm85CslQvqGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_933eg8m0MrhtMeta?.name ?? undefined,
    meta: _91caller_id_id_933eg8m0MrhtMeta || {},
    alias: _91caller_id_id_933eg8m0MrhtMeta?.alias || [],
    redirect: _91caller_id_id_933eg8m0MrhtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexyh4ywwq2uRMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexyh4ywwq2uRMeta?.path ?? "",
    meta: indexyh4ywwq2uRMeta || {},
    alias: indexyh4ywwq2uRMeta?.alias || [],
    redirect: indexyh4ywwq2uRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newr7ly6fn10lMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newr7ly6fn10lMeta?.path ?? "new",
    meta: newr7ly6fn10lMeta || {},
    alias: newr7ly6fn10lMeta?.alias || [],
    redirect: newr7ly6fn10lMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsYV8dpMdYRcMeta?.name ?? undefined,
    meta: caller_idsYV8dpMdYRcMeta || {},
    alias: caller_idsYV8dpMdYRcMeta?.alias || [],
    redirect: caller_idsYV8dpMdYRcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: edit9DlWpxjxTbMeta?.name ?? "admin-accounts-account_id-edit",
    path: edit9DlWpxjxTbMeta?.path ?? "edit",
    meta: edit9DlWpxjxTbMeta || {},
    alias: edit9DlWpxjxTbMeta?.alias || [],
    redirect: edit9DlWpxjxTbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqU5RiHiDT4Meta?.name ?? "admin-accounts-account_id",
    path: indexqU5RiHiDT4Meta?.path ?? "",
    meta: indexqU5RiHiDT4Meta || {},
    alias: indexqU5RiHiDT4Meta?.alias || [],
    redirect: indexqU5RiHiDT4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsIK2qe9x4nbMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93a1o6MbB4pAMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editobsqQ3fTdLMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editobsqQ3fTdLMeta?.path ?? "edit",
    meta: editobsqQ3fTdLMeta || {},
    alias: editobsqQ3fTdLMeta?.alias || [],
    redirect: editobsqQ3fTdLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexP8QrwfW1oEMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexP8QrwfW1oEMeta?.path ?? "",
    meta: indexP8QrwfW1oEMeta || {},
    alias: indexP8QrwfW1oEMeta?.alias || [],
    redirect: indexP8QrwfW1oEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93a1o6MbB4pAMeta?.name ?? undefined,
    meta: _91sms_masking_id_93a1o6MbB4pAMeta || {},
    alias: _91sms_masking_id_93a1o6MbB4pAMeta?.alias || [],
    redirect: _91sms_masking_id_93a1o6MbB4pAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexLPwyR7YP6eMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexLPwyR7YP6eMeta?.path ?? "",
    meta: indexLPwyR7YP6eMeta || {},
    alias: indexLPwyR7YP6eMeta?.alias || [],
    redirect: indexLPwyR7YP6eMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: new9eV5i9o7VpMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: new9eV5i9o7VpMeta?.path ?? "new",
    meta: new9eV5i9o7VpMeta || {},
    alias: new9eV5i9o7VpMeta?.alias || [],
    redirect: new9eV5i9o7VpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsIK2qe9x4nbMeta?.name ?? undefined,
    meta: sms_maskingsIK2qe9x4nbMeta || {},
    alias: sms_maskingsIK2qe9x4nbMeta?.alias || [],
    redirect: sms_maskingsIK2qe9x4nbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93hsWky97m7tMeta?.name ?? undefined,
    meta: _91account_id_93hsWky97m7tMeta || {},
    alias: _91account_id_93hsWky97m7tMeta?.alias || [],
    redirect: _91account_id_93hsWky97m7tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexuAaXI8Jn65Meta?.name ?? "admin-accounts",
    path: indexuAaXI8Jn65Meta?.path ?? "",
    meta: indexuAaXI8Jn65Meta || {},
    alias: indexuAaXI8Jn65Meta?.alias || [],
    redirect: indexuAaXI8Jn65Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selection3hv3lmy2LZMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selection3hv3lmy2LZMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selection3hv3lmy2LZMeta || {},
    alias: wizard_plan_selection3hv3lmy2LZMeta?.alias || [],
    redirect: wizard_plan_selection3hv3lmy2LZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardFKkfLPfqtHMeta?.name ?? "admin-accounts-wizard",
    path: wizardFKkfLPfqtHMeta?.path ?? "wizard",
    meta: wizardFKkfLPfqtHMeta || {},
    alias: wizardFKkfLPfqtHMeta?.alias || [],
    redirect: wizardFKkfLPfqtHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accountsIZLUwA90v8Meta?.name ?? undefined,
    meta: accountsIZLUwA90v8Meta || {},
    alias: accountsIZLUwA90v8Meta?.alias || [],
    redirect: accountsIZLUwA90v8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersZMTMM6hSXZMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93SqyUGx3IwzMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editrFS4640vHqMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editrFS4640vHqMeta?.path ?? "edit",
    meta: editrFS4640vHqMeta || {},
    alias: editrFS4640vHqMeta?.alias || [],
    redirect: editrFS4640vHqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeiOUaVWeEfMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexeiOUaVWeEfMeta?.path ?? "",
    meta: indexeiOUaVWeEfMeta || {},
    alias: indexeiOUaVWeEfMeta?.alias || [],
    redirect: indexeiOUaVWeEfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93SqyUGx3IwzMeta?.name ?? undefined,
    meta: _91admin_user_id_93SqyUGx3IwzMeta || {},
    alias: _91admin_user_id_93SqyUGx3IwzMeta?.alias || [],
    redirect: _91admin_user_id_93SqyUGx3IwzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexQ6VJ6EEmCLMeta?.name ?? "admin-admin_users",
    path: indexQ6VJ6EEmCLMeta?.path ?? "",
    meta: indexQ6VJ6EEmCLMeta || {},
    alias: indexQ6VJ6EEmCLMeta?.alias || [],
    redirect: indexQ6VJ6EEmCLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newKXlUXXyWMXMeta?.name ?? "admin-admin_users-new",
    path: newKXlUXXyWMXMeta?.path ?? "new",
    meta: newKXlUXXyWMXMeta || {},
    alias: newKXlUXXyWMXMeta?.alias || [],
    redirect: newKXlUXXyWMXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersZMTMM6hSXZMeta?.name ?? undefined,
    meta: admin_usersZMTMM6hSXZMeta || {},
    alias: admin_usersZMTMM6hSXZMeta?.alias || [],
    redirect: admin_usersZMTMM6hSXZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsXuM7upl3cBMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexFe1tC7qjgrMeta?.name ?? "admin-audit_trails",
    path: indexFe1tC7qjgrMeta?.path ?? "",
    meta: indexFe1tC7qjgrMeta || {},
    alias: indexFe1tC7qjgrMeta?.alias || [],
    redirect: indexFe1tC7qjgrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsXuM7upl3cBMeta?.name ?? undefined,
    meta: audit_trailsXuM7upl3cBMeta || {},
    alias: audit_trailsXuM7upl3cBMeta?.alias || [],
    redirect: audit_trailsXuM7upl3cBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingseMgx9Giot1Meta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93uZ8Rm8mMpIMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editChxaNKrXtQMeta?.name ?? "admin-billings-billing_id-edit",
    path: editChxaNKrXtQMeta?.path ?? "edit",
    meta: editChxaNKrXtQMeta || {},
    alias: editChxaNKrXtQMeta?.alias || [],
    redirect: editChxaNKrXtQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSMRB1UFXipMeta?.name ?? "admin-billings-billing_id",
    path: indexSMRB1UFXipMeta?.path ?? "",
    meta: indexSMRB1UFXipMeta || {},
    alias: indexSMRB1UFXipMeta?.alias || [],
    redirect: indexSMRB1UFXipMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93uZ8Rm8mMpIMeta?.name ?? undefined,
    meta: _91billing_id_93uZ8Rm8mMpIMeta || {},
    alias: _91billing_id_93uZ8Rm8mMpIMeta?.alias || [],
    redirect: _91billing_id_93uZ8Rm8mMpIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexGiJ6TGpgi1Meta?.name ?? "admin-billings",
    path: indexGiJ6TGpgi1Meta?.path ?? "",
    meta: indexGiJ6TGpgi1Meta || {},
    alias: indexGiJ6TGpgi1Meta?.alias || [],
    redirect: indexGiJ6TGpgi1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newB9R71adLMJMeta?.name ?? "admin-billings-new",
    path: newB9R71adLMJMeta?.path ?? "new",
    meta: newB9R71adLMJMeta || {},
    alias: newB9R71adLMJMeta?.alias || [],
    redirect: newB9R71adLMJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingseMgx9Giot1Meta?.name ?? undefined,
    meta: billingseMgx9Giot1Meta || {},
    alias: billingseMgx9Giot1Meta?.alias || [],
    redirect: billingseMgx9Giot1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idshiQvROtqWeMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_939DflBHHz7oMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editzbbPsjHEjdMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editzbbPsjHEjdMeta?.path ?? "edit",
    meta: editzbbPsjHEjdMeta || {},
    alias: editzbbPsjHEjdMeta?.alias || [],
    redirect: editzbbPsjHEjdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFfq1esjWwEMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexFfq1esjWwEMeta?.path ?? "",
    meta: indexFfq1esjWwEMeta || {},
    alias: indexFfq1esjWwEMeta?.alias || [],
    redirect: indexFfq1esjWwEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_939DflBHHz7oMeta?.name ?? undefined,
    meta: _91caller_id_id_939DflBHHz7oMeta || {},
    alias: _91caller_id_id_939DflBHHz7oMeta?.alias || [],
    redirect: _91caller_id_id_939DflBHHz7oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexuWICYQaYDPMeta?.name ?? "admin-caller_ids",
    path: indexuWICYQaYDPMeta?.path ?? "",
    meta: indexuWICYQaYDPMeta || {},
    alias: indexuWICYQaYDPMeta?.alias || [],
    redirect: indexuWICYQaYDPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: new8hfA44Xkf0Meta?.name ?? "admin-caller_ids-new",
    path: new8hfA44Xkf0Meta?.path ?? "new",
    meta: new8hfA44Xkf0Meta || {},
    alias: new8hfA44Xkf0Meta?.alias || [],
    redirect: new8hfA44Xkf0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idshiQvROtqWeMeta?.name ?? undefined,
    meta: caller_idshiQvROtqWeMeta || {},
    alias: caller_idshiQvROtqWeMeta?.alias || [],
    redirect: caller_idshiQvROtqWeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_934wbz9NkCmrMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: edit0zo0e7nUdSMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: edit0zo0e7nUdSMeta?.path ?? "edit",
    meta: edit0zo0e7nUdSMeta || {},
    alias: edit0zo0e7nUdSMeta?.alias || [],
    redirect: edit0zo0e7nUdSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexlKGiXnqJrCMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexlKGiXnqJrCMeta?.path ?? "",
    meta: indexlKGiXnqJrCMeta || {},
    alias: indexlKGiXnqJrCMeta?.alias || [],
    redirect: indexlKGiXnqJrCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_934wbz9NkCmrMeta?.name ?? undefined,
    meta: _91campaign_setting_id_934wbz9NkCmrMeta || {},
    alias: _91campaign_setting_id_934wbz9NkCmrMeta?.alias || [],
    redirect: _91campaign_setting_id_934wbz9NkCmrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsY69l7Nx2hIMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93yL0ptyLcb4Meta?.path ?? ":campaign_id()",
    children: [
  {
    name: editDLrHgEN43CMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editDLrHgEN43CMeta?.path ?? "edit",
    meta: editDLrHgEN43CMeta || {},
    alias: editDLrHgEN43CMeta?.alias || [],
    redirect: editDLrHgEN43CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWmeem4BMN5Meta?.name ?? "admin-campaigns-campaign_id",
    path: indexWmeem4BMN5Meta?.path ?? "",
    meta: indexWmeem4BMN5Meta || {},
    alias: indexWmeem4BMN5Meta?.alias || [],
    redirect: indexWmeem4BMN5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionsNc8wWEJdRiMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_93kDOcS90aplMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: edit0dsGEmcNPdMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: edit0dsGEmcNPdMeta?.path ?? "edit",
    meta: edit0dsGEmcNPdMeta || {},
    alias: edit0dsGEmcNPdMeta?.alias || [],
    redirect: edit0dsGEmcNPdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9eFSMGrEKUMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: index9eFSMGrEKUMeta?.path ?? "",
    meta: index9eFSMGrEKUMeta || {},
    alias: index9eFSMGrEKUMeta?.alias || [],
    redirect: index9eFSMGrEKUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_93kDOcS90aplMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_93kDOcS90aplMeta || {},
    alias: _91outbound_call_transaction_id_93kDOcS90aplMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_93kDOcS90aplMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexK98YhVgwzmMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexK98YhVgwzmMeta?.path ?? "",
    meta: indexK98YhVgwzmMeta || {},
    alias: indexK98YhVgwzmMeta?.alias || [],
    redirect: indexK98YhVgwzmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newAh1wJhqGxOMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newAh1wJhqGxOMeta?.path ?? "new",
    meta: newAh1wJhqGxOMeta || {},
    alias: newAh1wJhqGxOMeta?.alias || [],
    redirect: newAh1wJhqGxOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionsNc8wWEJdRiMeta?.name ?? undefined,
    meta: outbound_call_transactionsNc8wWEJdRiMeta || {},
    alias: outbound_call_transactionsNc8wWEJdRiMeta?.alias || [],
    redirect: outbound_call_transactionsNc8wWEJdRiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93yL0ptyLcb4Meta?.name ?? undefined,
    meta: _91campaign_id_93yL0ptyLcb4Meta || {},
    alias: _91campaign_id_93yL0ptyLcb4Meta?.alias || [],
    redirect: _91campaign_id_93yL0ptyLcb4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: index8iEUaxko1uMeta?.name ?? "admin-campaigns",
    path: index8iEUaxko1uMeta?.path ?? "",
    meta: index8iEUaxko1uMeta || {},
    alias: index8iEUaxko1uMeta?.alias || [],
    redirect: index8iEUaxko1uMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newVWh8qLWAXyMeta?.name ?? "admin-campaigns-new",
    path: newVWh8qLWAXyMeta?.path ?? "new",
    meta: newVWh8qLWAXyMeta || {},
    alias: newVWh8qLWAXyMeta?.alias || [],
    redirect: newVWh8qLWAXyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsY69l7Nx2hIMeta?.name ?? undefined,
    meta: campaignsY69l7Nx2hIMeta || {},
    alias: campaignsY69l7Nx2hIMeta?.alias || [],
    redirect: campaignsY69l7Nx2hIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsrr9mITWvgEMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93ALIgQb5QfMMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexhR8arxEfnuMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexhR8arxEfnuMeta?.path ?? "",
    meta: indexhR8arxEfnuMeta || {},
    alias: indexhR8arxEfnuMeta?.alias || [],
    redirect: indexhR8arxEfnuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93ALIgQb5QfMMeta?.name ?? undefined,
    meta: _91custom_email_id_93ALIgQb5QfMMeta || {},
    alias: _91custom_email_id_93ALIgQb5QfMMeta?.alias || [],
    redirect: _91custom_email_id_93ALIgQb5QfMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexavbMHimUQlMeta?.name ?? "admin-custom_emails",
    path: indexavbMHimUQlMeta?.path ?? "",
    meta: indexavbMHimUQlMeta || {},
    alias: indexavbMHimUQlMeta?.alias || [],
    redirect: indexavbMHimUQlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newTIGcJatkZlMeta?.name ?? "admin-custom_emails-new",
    path: newTIGcJatkZlMeta?.path ?? "new",
    meta: newTIGcJatkZlMeta || {},
    alias: newTIGcJatkZlMeta?.alias || [],
    redirect: newTIGcJatkZlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsrr9mITWvgEMeta?.name ?? undefined,
    meta: custom_emailsrr9mITWvgEMeta || {},
    alias: custom_emailsrr9mITWvgEMeta?.alias || [],
    redirect: custom_emailsrr9mITWvgEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardc59NiCtcS8Meta?.path ?? "dashboard",
    children: [
  {
    name: indexoGrZNoCEQ2Meta?.name ?? "admin-dashboard",
    path: indexoGrZNoCEQ2Meta?.path ?? "",
    meta: indexoGrZNoCEQ2Meta || {},
    alias: indexoGrZNoCEQ2Meta?.alias || [],
    redirect: indexoGrZNoCEQ2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardc59NiCtcS8Meta?.name ?? undefined,
    meta: dashboardc59NiCtcS8Meta || {},
    alias: dashboardc59NiCtcS8Meta?.alias || [],
    redirect: dashboardc59NiCtcS8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesL5OL76DjoLMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_939nfTX4XL3fMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexC1hUugGBsFMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexC1hUugGBsFMeta?.path ?? "",
    meta: indexC1hUugGBsFMeta || {},
    alias: indexC1hUugGBsFMeta?.alias || [],
    redirect: indexC1hUugGBsFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_939nfTX4XL3fMeta?.name ?? undefined,
    meta: _91inquiry_id_939nfTX4XL3fMeta || {},
    alias: _91inquiry_id_939nfTX4XL3fMeta?.alias || [],
    redirect: _91inquiry_id_939nfTX4XL3fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexhqhQhdfA58Meta?.name ?? "admin-inquiries",
    path: indexhqhQhdfA58Meta?.path ?? "",
    meta: indexhqhQhdfA58Meta || {},
    alias: indexhqhQhdfA58Meta?.alias || [],
    redirect: indexhqhQhdfA58Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newBUpky6NQ5nMeta?.name ?? "admin-inquiries-new",
    path: newBUpky6NQ5nMeta?.path ?? "new",
    meta: newBUpky6NQ5nMeta || {},
    alias: newBUpky6NQ5nMeta?.alias || [],
    redirect: newBUpky6NQ5nMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesL5OL76DjoLMeta?.name ?? undefined,
    meta: inquiriesL5OL76DjoLMeta || {},
    alias: inquiriesL5OL76DjoLMeta?.alias || [],
    redirect: inquiriesL5OL76DjoLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginS6KQz9A9okMeta?.path ?? "login",
    children: [
  {
    name: indexzMXWvtAET5Meta?.name ?? "admin-login",
    path: indexzMXWvtAET5Meta?.path ?? "",
    meta: indexzMXWvtAET5Meta || {},
    alias: indexzMXWvtAET5Meta?.alias || [],
    redirect: indexzMXWvtAET5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginS6KQz9A9okMeta?.name ?? undefined,
    meta: loginS6KQz9A9okMeta || {},
    alias: loginS6KQz9A9okMeta?.alias || [],
    redirect: loginS6KQz9A9okMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newYbStD5tJd7Meta?.name ?? "admin-password-new",
    path: newYbStD5tJd7Meta?.path ?? "password/new",
    meta: newYbStD5tJd7Meta || {},
    alias: newYbStD5tJd7Meta?.alias || [],
    redirect: newYbStD5tJd7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportsYVKEbONqJdMeta?.path ?? "reports",
    children: [
  {
    name: indexsOif6N6oT5Meta?.name ?? "admin-reports",
    path: indexsOif6N6oT5Meta?.path ?? "",
    meta: indexsOif6N6oT5Meta || {},
    alias: indexsOif6N6oT5Meta?.alias || [],
    redirect: indexsOif6N6oT5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsYVKEbONqJdMeta?.name ?? undefined,
    meta: reportsYVKEbONqJdMeta || {},
    alias: reportsYVKEbONqJdMeta?.alias || [],
    redirect: reportsYVKEbONqJdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingswdOofXzfXEMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93YKxk2MLW2JMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editqUNgBEY51xMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editqUNgBEY51xMeta?.path ?? "edit",
    meta: editqUNgBEY51xMeta || {},
    alias: editqUNgBEY51xMeta?.alias || [],
    redirect: editqUNgBEY51xMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextPIlkfgNQzMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indextPIlkfgNQzMeta?.path ?? "",
    meta: indextPIlkfgNQzMeta || {},
    alias: indextPIlkfgNQzMeta?.alias || [],
    redirect: indextPIlkfgNQzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93YKxk2MLW2JMeta?.name ?? undefined,
    meta: _91sms_masking_id_93YKxk2MLW2JMeta || {},
    alias: _91sms_masking_id_93YKxk2MLW2JMeta?.alias || [],
    redirect: _91sms_masking_id_93YKxk2MLW2JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexY2kue38ZSDMeta?.name ?? "admin-sms_maskings",
    path: indexY2kue38ZSDMeta?.path ?? "",
    meta: indexY2kue38ZSDMeta || {},
    alias: indexY2kue38ZSDMeta?.alias || [],
    redirect: indexY2kue38ZSDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newkTfYvGQKZtMeta?.name ?? "admin-sms_maskings-new",
    path: newkTfYvGQKZtMeta?.path ?? "new",
    meta: newkTfYvGQKZtMeta || {},
    alias: newkTfYvGQKZtMeta?.alias || [],
    redirect: newkTfYvGQKZtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingswdOofXzfXEMeta?.name ?? undefined,
    meta: sms_maskingswdOofXzfXEMeta || {},
    alias: sms_maskingswdOofXzfXEMeta?.alias || [],
    redirect: sms_maskingswdOofXzfXEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: users4ekXyan4CXMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_9306OJLxuFmcMeta?.path ?? ":user_id()",
    children: [
  {
    name: edit9UTZLzGrSEMeta?.name ?? "admin-users-user_id-edit",
    path: edit9UTZLzGrSEMeta?.path ?? "edit",
    meta: edit9UTZLzGrSEMeta || {},
    alias: edit9UTZLzGrSEMeta?.alias || [],
    redirect: edit9UTZLzGrSEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRp9TSwZ2IHMeta?.name ?? "admin-users-user_id",
    path: indexRp9TSwZ2IHMeta?.path ?? "",
    meta: indexRp9TSwZ2IHMeta || {},
    alias: indexRp9TSwZ2IHMeta?.alias || [],
    redirect: indexRp9TSwZ2IHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_9306OJLxuFmcMeta?.name ?? undefined,
    meta: _91user_id_9306OJLxuFmcMeta || {},
    alias: _91user_id_9306OJLxuFmcMeta?.alias || [],
    redirect: _91user_id_9306OJLxuFmcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: index1EGGAC7R9pMeta?.name ?? "admin-users",
    path: index1EGGAC7R9pMeta?.path ?? "",
    meta: index1EGGAC7R9pMeta || {},
    alias: index1EGGAC7R9pMeta?.alias || [],
    redirect: index1EGGAC7R9pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newvEl9Z2sCD8Meta?.name ?? "admin-users-new",
    path: newvEl9Z2sCD8Meta?.path ?? "new",
    meta: newvEl9Z2sCD8Meta || {},
    alias: newvEl9Z2sCD8Meta?.alias || [],
    redirect: newvEl9Z2sCD8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: users4ekXyan4CXMeta?.name ?? undefined,
    meta: users4ekXyan4CXMeta || {},
    alias: users4ekXyan4CXMeta?.alias || [],
    redirect: users4ekXyan4CXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_931axhXNScP6Meta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editMLd8dq1VWiMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editMLd8dq1VWiMeta?.path ?? "edit",
    meta: editMLd8dq1VWiMeta || {},
    alias: editMLd8dq1VWiMeta?.alias || [],
    redirect: editMLd8dq1VWiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQkRnkEEwGCMeta?.name ?? "admin-wallets-wallet_id",
    path: indexQkRnkEEwGCMeta?.path ?? "",
    meta: indexQkRnkEEwGCMeta || {},
    alias: indexQkRnkEEwGCMeta?.alias || [],
    redirect: indexQkRnkEEwGCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_931axhXNScP6Meta?.name ?? undefined,
    meta: _91wallet_id_931axhXNScP6Meta || {},
    alias: _91wallet_id_931axhXNScP6Meta?.alias || [],
    redirect: _91wallet_id_931axhXNScP6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminddZXzBMheZMeta || {},
    alias: adminddZXzBMheZMeta?.alias || [],
    redirect: adminddZXzBMheZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexyid105n2UAMeta?.name ?? "audit_logs",
    path: indexyid105n2UAMeta?.path ?? "/audit_logs",
    meta: indexyid105n2UAMeta || {},
    alias: indexyid105n2UAMeta?.alias || [],
    redirect: indexyid105n2UAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMxCu4EYzLYMeta?.name ?? "billings",
    path: indexMxCu4EYzLYMeta?.path ?? "/billings",
    meta: indexMxCu4EYzLYMeta || {},
    alias: indexMxCu4EYzLYMeta?.alias || [],
    redirect: indexMxCu4EYzLYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexIarHwlkNTrMeta?.name ?? "call_out-reports",
    path: indexIarHwlkNTrMeta?.path ?? "/call_out/reports",
    meta: indexIarHwlkNTrMeta || {},
    alias: indexIarHwlkNTrMeta?.alias || [],
    redirect: indexIarHwlkNTrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iuQsTgMn5AMeta?.name ?? "call_recordings-id",
    path: _91id_93iuQsTgMn5AMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93iuQsTgMn5AMeta || {},
    alias: _91id_93iuQsTgMn5AMeta?.alias || [],
    redirect: _91id_93iuQsTgMn5AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEiJFww6DNnMeta?.name ?? "campaigns-id",
    path: indexEiJFww6DNnMeta?.path ?? "/campaigns/:id()",
    meta: indexEiJFww6DNnMeta || {},
    alias: indexEiJFww6DNnMeta?.alias || [],
    redirect: indexEiJFww6DNnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbgrZhP0tnnMeta?.name ?? "campaigns",
    path: indexbgrZhP0tnnMeta?.path ?? "/campaigns",
    meta: indexbgrZhP0tnnMeta || {},
    alias: indexbgrZhP0tnnMeta?.alias || [],
    redirect: indexbgrZhP0tnnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexzRYCRQicecMeta?.name ?? "dashboard",
    path: indexzRYCRQicecMeta?.path ?? "/dashboard",
    meta: indexzRYCRQicecMeta || {},
    alias: indexzRYCRQicecMeta?.alias || [],
    redirect: indexzRYCRQicecMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexq61bvnzQF1Meta?.name ?? "help",
    path: indexq61bvnzQF1Meta?.path ?? "/help",
    meta: indexq61bvnzQF1Meta || {},
    alias: indexq61bvnzQF1Meta?.alias || [],
    redirect: indexq61bvnzQF1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexgiM0ZGBKqgMeta?.name ?? "index",
    path: indexgiM0ZGBKqgMeta?.path ?? "/",
    meta: indexgiM0ZGBKqgMeta || {},
    alias: indexgiM0ZGBKqgMeta?.alias || [],
    redirect: indexgiM0ZGBKqgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexZmAlPquTNDMeta?.name ?? "inquiry",
    path: indexZmAlPquTNDMeta?.path ?? "/inquiry",
    meta: indexZmAlPquTNDMeta || {},
    alias: indexZmAlPquTNDMeta?.alias || [],
    redirect: indexZmAlPquTNDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editor6tZb0FcfLoMeta?.name ?? "ivr_trees-id-editor",
    path: editor6tZb0FcfLoMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editor6tZb0FcfLoMeta || {},
    alias: editor6tZb0FcfLoMeta?.alias || [],
    redirect: editor6tZb0FcfLoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: index6IMMw3ceTjMeta?.name ?? "ivr_trees",
    path: index6IMMw3ceTjMeta?.path ?? "/ivr_trees",
    meta: index6IMMw3ceTjMeta || {},
    alias: index6IMMw3ceTjMeta?.alias || [],
    redirect: index6IMMw3ceTjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailVaGlcKa5BdMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailVaGlcKa5BdMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailVaGlcKa5BdMeta || {},
    alias: verify_emailVaGlcKa5BdMeta?.alias || [],
    redirect: verify_emailVaGlcKa5BdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardhYkjUPEsTNMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardhYkjUPEsTNMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardhYkjUPEsTNMeta || {},
    alias: welcome_dashboardhYkjUPEsTNMeta?.alias || [],
    redirect: welcome_dashboardhYkjUPEsTNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: index3kwEan0LG9Meta?.name ?? "profiles",
    path: index3kwEan0LG9Meta?.path ?? "/profiles",
    meta: index3kwEan0LG9Meta || {},
    alias: index3kwEan0LG9Meta?.alias || [],
    redirect: index3kwEan0LG9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexKPxeQiw09CMeta?.name ?? "sub_users-users",
    path: indexKPxeQiw09CMeta?.path ?? "/sub_users/users",
    meta: indexKPxeQiw09CMeta || {},
    alias: indexKPxeQiw09CMeta?.alias || [],
    redirect: indexKPxeQiw09CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: index2tfIls3lcrMeta?.name ?? "terms_and_conditions",
    path: index2tfIls3lcrMeta?.path ?? "/terms_and_conditions",
    meta: index2tfIls3lcrMeta || {},
    alias: index2tfIls3lcrMeta?.alias || [],
    redirect: index2tfIls3lcrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexbWVp8SdRt9Meta?.name ?? "users-password-edit",
    path: indexbWVp8SdRt9Meta?.path ?? "/users/password/edit",
    meta: indexbWVp8SdRt9Meta || {},
    alias: indexbWVp8SdRt9Meta?.alias || [],
    redirect: indexbWVp8SdRt9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index7oUqFLRCI8Meta?.name ?? "users-password-new",
    path: index7oUqFLRCI8Meta?.path ?? "/users/password/new",
    meta: index7oUqFLRCI8Meta || {},
    alias: index7oUqFLRCI8Meta?.alias || [],
    redirect: index7oUqFLRCI8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexpxiu5IVJt2Meta?.name ?? "users-sign_in",
    path: indexpxiu5IVJt2Meta?.path ?? "/users/sign_in",
    meta: indexpxiu5IVJt2Meta || {},
    alias: indexpxiu5IVJt2Meta?.alias || [],
    redirect: indexpxiu5IVJt2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3272106254/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]